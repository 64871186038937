import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
// import Title from "../components/title"
import Inview from "../components/inview";
const Lashingbelt = ({ data }) => {
  const pageData = {
    titleClass: "lashingbelt",
    title: "LASHING BELT",
    discription: "あらゆる荷物の固定・荷崩れ防止に使用する、やさしくがっちりと固定するラチェットバックル式ベルトです。 材質は摩耗に強いポリエステル製で、耐水性、耐候性に優れています。 小さな力で大きな荷締めでも簡単・スピーディーに固縛し作業効率UP！",
    image: 12,
  };
  const seodata = {
    title: "固縛作業の強い味方、ラッシングベルト [LASHING BELT] アヴィエラン株式会社",
    seo: {
      description: "あらゆる荷物の固定・荷崩れ防止に使用する、やさしくがっちりと固定するラチェットバックル式ベルトです。 材質は摩耗に強いポリエステル製で、耐水性、耐候性に優れています。 小さな力で大きな荷締めでも簡単・スピーディーに固縛し作業効率UP！",
      image: {
        url: data.datoCmsImage.topimage[12].url,
      },
    },
    slug: "lashingbelt",
  };
  //   const newsData = data.allDatoCmsNews.nodes
  const imageData = data.datoCmsImage;
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <div className="pagetitle lashingbelt">
        <div className="fixedtitleimg productimg">
          <Img fluid={imageData.productsImg[11].item} alt="固縛作業の強い味方 ラッシングベルト" />
        </div>
        <div className="fixedtexttitle">
          <h1>LASHING BELT</h1>
        </div>
      </div>
      <div className="pankz">
        <Link to="/">トップ</Link>
        <Link to="/logisticsequipment/">物流機器事業本部</Link>
        <p>LASHING BELT</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[11].item} alt="固縛作業の強い味方 ラッシングベルト" />
            <Inview c_name="title" tag="h2">
              固縛作業の強い味方 ラッシングベルト
              <span>Strong ally for lashing work</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              あらゆる荷物の固定・荷崩れ防止に使用する、やさしくがっちりと固定するラチェットバックル式ベルトです。 材質は摩耗に強いポリエステル製で、耐水性、耐候性に優れています。 小さな力で大きな荷締めでも簡単・スピーディーに固縛し作業効率UP！
            </Inview>
          </div>
        </div>
        <div className="itemdiscription round">
          <div>
            <h3>補償も万全　生産物賠償責任保険［PL保険］加入商品</h3>
            <p>製品の欠陥によって、その製品の消費者その他第三者が生命・身体または財産に被害を被った場合、その製品の製造・販売に関与した事業者が、被害者に対して負うべき法律上の損害賠償責任をＰＬ（製造物責任）といいます。</p>
          </div>
          <div>
            <h3>作業環境の改善　金具表面処理　三価クロム対応</h3>
            <p>
              弊社ラッシングベルトは全金具部の表面処理は三価クロムを使用しております。三価クロムは、六価クロムの最有力代替技術であり三価クロム塩を主成分とする為毒性はなく、作業環境が改善されます。
              <br />
              <br />
              {/* 金メッキ処理（六価クロム）。六価クロムは極めて強い毒性を持つことで知られており（※1）ＲｏＨＳ指令により指定値が定められています。 */}
            </p>
          </div>
          <div>
            <h3>安全性の向上　ダブルセーフティロック</h3>
            <p>
              今までのラッシングベルトは荷物を固定してラチェットバックルの金具を締めても、ハンドル部に何かが当たるなどすると、ハンドルが開放する危険性がありました。
              <br />
              <br />
              ダブルセーフティロックはハンドルを全開放するときも、ハンドル部内にあるレバーを引かなくては動かない構造になっているので、安全度が非常に高い商品です。
            </p>
          </div>

          <div>
            <h3>ベルトの保護（耐久性アップ）　保護スリーブ</h3>
            <p>ベルト部の摩耗・切断を防ぎます。</p>
          </div>

          <div>
            <h3>ベルトの安定性-50mm幅</h3>
            <p>
              ・ポリエステル原糸100％使用
              <br />
              ・伸び率が低い
              <br />
              ・伸び率にバラツキがない
              <br />
              ・耐久性に優れている
            </p>
          </div>
          <div>
            <h3>荷物の傷付き防止　バックルプロテクター</h3>
            <p>
              ラチェットバックルの底部で荷物を傷つけるのを防ぎます。
              <br />
              ※プロテクターの仕様は写真と異なる場合があります。
            </p>
          </div>
        </div>

        <div className="lineup round">
          <div>
            <p className="title">
              COLOR LINE UP
              <br />
              [ONLINE STORE]
            </p>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=416" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[85].item} alt="50mm幅 ブルー" />
              <span>50&#13212;幅 ブルー</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=417" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[42].item} alt="50mm幅 オールブラック" />
              <span>50&#13212;幅 オールブラック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=418" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[40].item} alt="50mm幅 ホワイト" />
              <span>50&#13212;幅 ホワイト</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=415" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[46].item} alt="50mm幅 反射レッド" />
              <span>50&#13212;幅 反射レッド</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=419" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[41].item} alt="50mm幅 イエロー(強化タイプ)" />
              <span>50&#13212;幅 イエロー(強化タイプ)</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=420" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[37].item} alt="50mm幅 グレー" />
              <span>50&#13212;幅 グレー</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=421" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[45].item} alt="50mm幅 OD" />
              <span>50&#13212;幅 OD</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;heat_resisting=459" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[86].item} alt="50mm幅 オールステンレス" />
              <span>50&#13212;幅 オールステンレス</span>
            </a>
          </div>

          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=660" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[75].item} alt="50mm幅 バイカラー (ブラック/グレー)" />
              <span>
                50&#13212;幅 バイカラー
                <br />
                (ブラック/グレー)
              </span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=661" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[76].item} alt="50mm幅 バイカラー (ブラック/ホワイト)" />
              <span>
                50&#13212;幅 バイカラー
                <br />
                (ブラック/ホワイト)
              </span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=662" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[77].item} alt="50mm幅 バイカラー (OD/ブラック)" />
              <span>
                50&#13212;幅 バイカラー
                <br />
                (OD/ブラック)
              </span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=663" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[78].item} alt="50mm幅 バイカラー (ホワイト/オレンジ)" />
              <span>
                50&#13212;幅 バイカラー
                <br />
                (ホワイト/オレンジ)
              </span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=845" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[79].item} alt="50mm幅 バイカラー (オレンジ/ブラック) 強化タイプ" />
              <span>
                50&#13212;幅 バイカラー
                <br />
                (オレンジ/ブラック) 強化タイプ
              </span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=844" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[80].item} alt="50mm幅 バイカラー (ブラック/イエロー) 強化タイプ" />
              <span>
                50&#13212;幅 バイカラー
                <br />
                (ブラック/イエロー) 強化タイプ
              </span>
            </a>
          </div>

          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=429" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[81].item} alt="35&#13212;幅 オレンジ" />
              <span>35&#13212;幅 オレンジ</span>
            </a>
          </div>

          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;heat_resisting=458" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[43].item} alt="耐熱ラッシングベルト" />
              <span>耐熱ラッシングベルト</span>
            </a>
          </div>

          <div>
            <p className="title">
              COLOR LINE UP
              <br />
              [ONLINE STORE]
            </p>
          </div>
        </div>

        <div className="lineup round">
          <div>
            <p className="title">
              HOOK LINE UP
              <br />
              [ONLINE STORE]
            </p>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=450" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[87].item} alt="Rフック" />
              <span>Rフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=449" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[88].item} alt="Jフック" />
              <span>Jフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=851" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[89].item} alt="Jフック(シングル)" />
              <span>Jフック(シングル)</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=448" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[90].item} alt="Iフック" />
              <span>Iフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=447" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[91].item} alt="RJフック" />
              <span>RJフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=446" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[92].item} alt="IJフック" />
              <span>IJフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=445" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[93].item} alt="スナップフック" />
              <span>スナップフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=565" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[94].item} alt="スナップフック ツイスト" />
              <span>スナップフック ツイスト</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=444" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[95].item} alt="フラットフック" />
              <span>フラットフック</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=443" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[96].item} alt="デルタリング" />
              <span>デルタリング</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;hook=442" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[97].item} alt="エンドレスタイプ" />
              <span>エンドレスタイプ</span>
            </a>
          </div>
          {/* <div><p className="title">HOOK LINE UP<br />[ONLINE STORE]</p></div> */}
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[85].item} alt=" 固縛ベルトとしてスタンダードなサイズ。" />
            <Inview c_name="title" tag="h2">
              50㎜幅 ブルー
              <span>50mm Blue</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              固縛ベルトとしてスタンダードなサイズ。 汎用性が高く様々な現場で使用することが出来ます。 ポリエステル原糸を100％使用しています。 その為伸び率が低く、耐久性に優れている為、高い安全性を持ち合わせています。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=416" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[42].item} alt="50㎜幅 オールブラック" />
            <Inview c_name="title" tag="h2">
              50㎜幅 オールブラック
              <span>50mm ALL Black</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              50mm幅のバックル、縫製、先端金具全てがブラックに統一された製品です。 ブラックで統一されている為ライブハウスやスタジオ・イベントの機材の固縛に使用することで、会場の雰囲気を壊すことなく、作業することが出来ます。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=417" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[40].item} alt="50㎜幅 ホワイト" />
            <Inview c_name="title" tag="h2">
              50㎜幅 ホワイト
              <span>50mm White</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              50mm幅のラッシングベルトのベルト・縫製が白色の製品です。 結婚式場等従来使用する以外の場所でも使用する事も出来る、汎用性が高い製品になります。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=418" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[46].item} alt="50㎜幅 レッド" />
            <Inview c_name="title" tag="h2">
              50㎜幅 レッド
              <span>50mm Red</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              50mm幅のラッシングベルトのベルトに反射材を採用。 資材倉庫での目印、平台トラックやリフト作業での注意喚起を促すことが出来る、安全意識の向上に特化した製品になります。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=415" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[41].item} alt="50㎜幅 イエロー" />
            <Inview c_name="title" tag="h2">
              50㎜幅 イエロー
              <span>50mm Yellow</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ベルトカラーの黄色は作業現場での周囲への注意喚起に繋がり、安全への意識向上に最適な商品です。さらにベルトの破断荷重が高い強化タイプです。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=419" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[37].item} alt="50㎜幅 グレー" />
            <Inview c_name="title" tag="h2">
              50㎜幅 グレー
              <span>50mm Gray</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ベーシックなグレーカラーはどの現場でも映え安心して使用できます。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=420" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[45].item} alt="50㎜幅 OD" />
            <Inview c_name="title" tag="h2">
              50㎜幅 OD
              <span>50mm OD</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ベルトカラーにODカラーを使い、スタイリッシュなデザイン
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=421" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[75].item} alt="50㎜幅 バイカラー(ブラック/グレー)" />
            <Inview c_name="title" tag="h2">
              50㎜幅 バイカラー(ブラック/グレー)
              <span>50mm Bicolor(Black/Gray)</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              シックな色合いなので、暗い場所になじみつつ、存在感があります。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=660" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[76].item} alt="50㎜幅 バイカラー(ブラック/ホワイト)" />
            <Inview c_name="title" tag="h2">
              50㎜幅 バイカラー(ブラック/ホワイト)
              <span>50mm Bicolor(Black/White)</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              モノトーンで主張しすぎず、安定の存在感があります。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=661" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[77].item} alt="50㎜幅 バイカラー(OD/ブラック)" />
            <Inview c_name="title" tag="h2">
              50㎜幅 バイカラー(OD/ブラック)
              <span>50mm Bicolor(Od/Black)</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              アースカラーのOD(オリーブドラ)とブラックでシックにセットアップ
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=662" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[78].item} alt="50㎜幅 バイカラー(ホワイト/オレンジ)" />
            <Inview c_name="title" tag="h2">
              50㎜幅 バイカラー(ホワイト/オレンジ)
              <span>50mm Bicolor(White/Orange)</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              清潔感・明るく開放的・爽やかなイメージを与えてくれます。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=663" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[79].item} alt="50㎜幅 バイカラー(オレンジ/ブラック)" />
            <Inview c_name="title" tag="h2">
              50㎜幅 バイカラー(オレンジ/ブラック)
              <span>50mm Bicolor(Orange/Black)</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              オレンジはポジティブな気分になりつつも、ブラックで気を引き締めて作業に取り組むことができます。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=845" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[80].item} alt="50㎜幅 バイカラー(ブラック/イエロー)" />
            <Inview c_name="title" tag="h2">
              50㎜幅 バイカラー(ブラック/イエロー)
              <span>50mm Bicolor(Black/Yellow)</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              目を引くイエローは、ブラックとセットアップすることで注意喚起効果がアップします。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;color=844" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[86].item} alt="50㎜幅 オールステンレス" />
            <Inview c_name="title" tag="h2">
              50㎜幅 オールステンレス
              <span>50mm ALL Stainless</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ステンレス製のラチェットバックルと金具を使用し、錆に強い製品。 雨風に晒されるハードな環境での作業に力を発揮します。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=428&amp;heat_resisting=459" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[81].item} alt="35㎜幅 オレンジ" />
            <Inview c_name="title" tag="h2">
              35㎜幅 オレンジ
              <span>35mm Orange</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              少し小さい為取り回しが良く、とても扱いやすい製品です。 比較的重量が軽い物やサイズが小さい荷物を固縛するのに最適です。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;belt_width=429" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[43].item} alt="50㎜幅・25㎜幅 耐熱" />
            <Inview c_name="title" tag="h2">
              50㎜幅・25㎜幅 耐熱
              <span>50mm 25mm Heat Resisting</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              耐熱仕様のラッシングベルトになります。 固縛する資材が非常に高温になる可能性がある作業でこの商品は最適です。
            </Inview>
            <a href="https://www.slingbelt.com/products/list?item_type=8&amp;heat_resisting=458" target="_blank" rel="noreferrer">
              <span>ご購入・詳細はこちら</span>
            </a>
          </div>
        </div>

        <div className="blueprint">
          <div>
            <h4>ITEM SPEC</h4>
            <div className="specimg">
              <Img fluid={data.datoCmsImage.productsImg[74].item} alt="ラチェットバックル" />
            </div>
          </div>
          {/* 1 */}
          <div>
            <h3>ラチェットバックル</h3>
            <Img fluid={data.datoCmsImage.productsImg[67].item} alt="ラチェットバックル" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>L</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>H</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>195</td>
                  <td>53</td>
                  <td>93</td>
                  <td>58</td>
                </tr>
                <tr>
                  <th>35mm幅</th>
                  <td>133</td>
                  <td>38</td>
                  <td>65</td>
                  <td>42</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 1 //*/}
          {/* 2 */}
          <div>
            <h3>Rフック</h3>
            <Img fluid={data.datoCmsImage.productsImg[72].item} alt="Rフック" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>L</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>H</th>
                  <th>T</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>34</td>
                  <td>72</td>
                  <td>52</td>
                  <td>13</td>
                  <td>3</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 2 //*/}
          {/* 3 */}
          <div>
            <h3>Jフック</h3>
            <Img fluid={data.datoCmsImage.productsImg[71].item} alt="Jフック" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>W3</th>
                  <th>T</th>
                  <th>L</th>
                  <th>W4</th>
                  <th>D</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>72</td>
                  <td>51</td>
                  <td>24</td>
                  <td>12</td>
                  <td>107</td>
                  <td>30</td>
                  <td>20&Phi;</td>
                </tr>
                <tr>
                  <th>35mm幅</th>
                  <td>57</td>
                  <td>39</td>
                  <td>19</td>
                  <td>9.5</td>
                  <td>85</td>
                  <td>30</td>
                  <td>20&Phi;</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 3 //*/}
          {/* 3-1 */}
          <div>
            <h3>Jフック(シングル)</h3>
            <Img fluid={data.datoCmsImage.productsImg[83].item} alt="Jフック(シングル)" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>W3</th>
                  <th>L</th>
                  <th>W4</th>
                  <th>D</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>82</td>
                  <td>52</td>
                  <td>15</td>
                  <td>127</td>
                  <td>34</td>
                  <td>19&Phi;</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 3-1 //*/}
          {/* 4 */}
          <div>
            <h3>Iフック</h3>
            <Img fluid={data.datoCmsImage.productsImg[70].item} alt="Iフック" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>L</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>180</td>
                </tr>
                <tr>
                  <th>35mm幅</th>
                  <td>160</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 4 //*/}
          {/* 5 */}
          <div>
            <h3>スナップフック</h3>
            <Img fluid={data.datoCmsImage.productsImg[73].item} alt="スナップフック" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>W3</th>
                  <th>T</th>
                  <th>D</th>
                  <th>L</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>77</td>
                  <td>71</td>
                  <td>51</td>
                  <td>5</td>
                  <td>26</td>
                  <td>155</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 5 //*/}
          {/* 6 */}
          <div>
            <h3>フラットフック</h3>
            <Img fluid={data.datoCmsImage.productsImg[69].item} alt="フラットフック" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>T</th>
                  <th>D</th>
                  <th>L</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>41</td>
                  <td>50</td>
                  <td>4.5</td>
                  <td>16</td>
                  <td>76</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 6 //*/}
          {/* 7 */}
          <div>
            <h3>デルタリング</h3>
            <Img fluid={data.datoCmsImage.productsImg[68].item} alt="デルタリング" />
            <div className="blueprinttable">
              <table>
                <tr>
                  <th>単位mm</th>
                  <th>W</th>
                  <th>L</th>
                  <th>T</th>
                </tr>
                <tr>
                  <th>50mm幅</th>
                  <td>51</td>
                  <td>43</td>
                  <td>8</td>
                </tr>
              </table>
            </div>
          </div>
          {/* 7 //*/}
        </div>
      </div>
    </Layout>
  );
};

export default Lashingbelt;

export const query = graphql`
  query {
    allDatoCmsNews {
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        item: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
